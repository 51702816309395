export const AUTH_START = "AUTH_START"
export const AUTH_SUCCESS = "AUTH_SUCCESS"
export const AUTH_FAIL = "AUTH_FAIL"

export const AUTH_LOGOUT = "AUTH_LOGOUT"

export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH"

export const PASTE_ADD_RESET = "PASTE_ADD_RESET"
export const PASTE_ADD_START = "PASTE_ADD_START"
export const PASTE_ADD_SUCCESS = "PASTE_ADD_SUCCESS"
export const PASTE_ADD_FAIL = "PASTE_ADD_FAIL"

export const PASTE_GET_RESET = "PASTE_GET_RESET"
export const PASTE_GET_START = "PASTE_GET_START"
export const PASTE_GET_SUCCESS = "PASTE_GET_SUCCESS"
export const PASTE_GET_FAIL = "PASTE_GET_FAIL"

export const PUB_PASTE_GET_RESET = "PUB_PASTE_GET_RESET"
export const PUB_PASTE_GET_START = "PUB_PASTE_GET_START"
export const PUB_PASTE_GET_SUCCESS = "PUB_PASTE_GET_SUCCESS"
export const PUB_PASTE_GET_FAIL = "PUB_PASTE_GET_FAIL"

export const PASTE_LIST_RESET = "PASTE_LIST_RESET"
export const PASTE_LIST_START = "PASTE_LIST_START"
export const PASTE_LIST_SUCCESS = "PASTE_LIST_SUCCESS"
export const PASTE_LIST_FAIL = "PASTE_LIST_FAIL"

export const PASTE_DELETE_START = "PASTE_DELETE_START"
export const PASTE_DELETE_FAIL = "PASTE_DELETE_FAIL"
export const PASTE_DELETE_SUCCESS = "PASTE_DELETE_SUCCESS"

export const PASTE_UPDATE_START = "PASTE_UPDATE_START"
export const PASTE_UPDATE_FAIL = "PASTE_UPDATE_FAIL"
export const PASTE_UPDATE_SUCCESS = "PASTE_UPDATE_SUCCESS"


export const USER_LIST_RESET = "USER_LIST_RESET"
export const USER_LIST_START = "USER_LIST_START"
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS"
export const USER_LIST_FAIL = "USER_LIST_FAIL"

export const USER_GET_RESET = "USER_GET_RESET"
export const USER_GET_START = "USER_GET_START"
export const USER_GET_SUCCESS = "USER_GET_SUCCESS"
export const USER_GET_FAIL = "USER_GET_FAIL"

export const USER_UPDATE_START = "USER_UPDATE_START"
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS"
export const USER_UPDATE_FAIL = "USER_UPDATE_FAIL"

export const USER_DELETE_START = "USER_DELETE_START"
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS"
export const USER_DELETE_FAIL = "USER_DELETE_FAIL"

export const USER_CREATE_RESET = "USER_CREATE_RESET"
export const USER_CREATE_START = "USER_CREATE_START"
export const USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS"
export const USER_CREATE_FAIL = "USER_CREATE_FAIL"

export const PASTE_SHARE_LIST_RESET = "PASTE_SHARE_LIST_RESET"
export const PASTE_SHARE_LIST_START = "PASTE_SHARE_LIST_START"
export const PASTE_SHARE_LIST_SUCCESS = "PASTE_SHARE_LIST_SUCCESS"
export const PASTE_SHARE_LIST_FAIL = "PASTE_SHARE_LIST_FAIL"

export const PASTE_SHARE_ADD_RESET = "PASTE_SHARE_ADD_RESET"
export const PASTE_SHARE_ADD_START = "PASTE_SHARE_ADD_START"
export const PASTE_SHARE_ADD_SUCCESS = "PASTE_SHARE_ADD_SUCCESS"
export const PASTE_SHARE_ADD_FAIL = "PASTE_SHARE_ADD_FAIL"

export const PASTE_SHARE_REMOVE_RESET = "PASTE_SHARE_REMOVE_RESET"
export const PASTE_SHARE_REMOVE_START = "PASTE_SHARE_REMOVE_START"
export const PASTE_SHARE_REMOVE_SUCCESS = "PASTE_SHARE_REMOVE_SUCCESS"
export const PASTE_SHARE_REMOVE_FAIL = "PASTE_SHARE_REMOVE_FAIL"
